import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import FourCardArticle from '../FourCardArticle/FourCardArticle';
import { fireGAEvent } from '../../shared/trackingUtils';
import { fetchArticles } from '../PopularArticles/utils';
import throttle from '../../shared/throttle';

const PROGRESSIVE_LOAD_PERCENTAGE = 1;

/**
 * Fires a google analytics event
 * @param {String} label event label
 * @param {String} value event value
 */
const triggerGAEvent = (label, value) => {
	fireGAEvent({
		eventCategory: 'Template Area Interaction',
		eventAction: 'click',
		eventLabel: label,
		eventValue: value,
	});
};

/**
 * Creates inline ad component
 * @param {Boolean} isMobile Checks if the device is mobile
 * @param {String} className Additional class name
 * @param {Number} index number of mobilex index
 * @param {Object} customTargeting custom targeting for ad
 * @param addProgressiveAd function to add fbs-ad to shared progressive ads array
 * @param AdContainerComponent The component that will display the ad, e.g. AdContainer
 * @returns Inline ad component
 */
const InlineAdX = ({
	isMobile,
	className,
	index,
	customTargeting,
	addProgressiveAd,
	AdContainerComponent,
}) => (
	<AdContainerComponent
		type={isMobile ? 'inlineAdMobile' : 'inlineAd'}
		className={cx('inline-ad-container', 'xl-recirc-inline-ad', className)}
		showLabel
	>
		{isMobile ? (
			<fbs-ad
				progressive="true"
				position="mob-stream"
				id={`article-0-ad-${index}`}
				ad-id={`inline-article-0-ad-${index}`}
				class="article-mobile-ad"
				ref={addProgressiveAd}
				custom-targeting={`${customTargeting},mffxlrecirc:true`}
			/>
		) : (
			<fbs-ad
				progressive="true"
				position="topx"
				custom-targeting="topx:stream1,mffxlrecirc:true"
				class="topx-ad"
				ref={addProgressiveAd}
			/>
		)}
	</AdContainerComponent>
);

InlineAdX.propTypes = {
	isMobile: PropTypes.bool.isRequired,
	className: PropTypes.string,
	index: PropTypes.number,
	customTargeting: PropTypes.string.isRequired,
	addProgressiveAd: PropTypes.func.isRequired,
	AdContainerComponent: PropTypes.node.isRequired,
};

const XLRecirc = ({
	showAds = true,
	className,
	isVetted = false,
	vettedRecircData = [],
	mobileAdsSelector = '.article-mobile-ad',
	shouldForceRender,
	themeColor,
	CardArticleComponent,
	CardStreamComponent,
	AdContainerComponent,
}) => {
	const [data, setData] = useState(null);
	const [shouldBlockComponent, setShouldBlockComponent] = useState(true);
	const [isSmallDevice, setIsSmallDevice] = useState(false);
	const [mobileInlineAds, setMobileInlineAds] = useState(undefined);
	const [progressiveAds, setProgressiveAds] = useState([]);

	const addProgressiveAd = (ref) => {
		if (ref && !progressiveAds.includes(ref)) {
			setProgressiveAds((curAds) => [...curAds, ref]);
		}
	};

	useEffect(() => {
		const checkProgressiveAds = () => {
			progressiveAds.forEach((ad) => {
				const { bottom } = ad.getBoundingClientRect();

				// destroy ads when user scrolls past them into next article
				if (bottom < -window.innerHeight * PROGRESSIVE_LOAD_PERCENTAGE) {
					if (ad.div && (!ad.hasAttribute('destroyed') || ad.getAttribute('destroyed') === 'false')) {
						ad.destroyAd();
						ad.setAttribute('destroyed', 'true');
					}
					return;
				}

				if ((bottom - window.innerHeight < window.innerHeight * PROGRESSIVE_LOAD_PERCENTAGE)
					&& !ad.hasAttribute('display-called')
				) {
					if (ad.hasAttribute('destroyed') && ad.getAttribute('destroyed') === 'true') {
						ad.removeAttribute('destroyed');
						ad.recallAd();
					} else {
						ad.display();
					}
				}
			});
		};
		checkProgressiveAds();
		const scrollHandler = throttle(checkProgressiveAds, 200);
		window.addEventListener('scroll', scrollHandler, { passive: true });
		return () => {
			window.removeEventListener('scroll', scrollHandler);
		};
	}, [progressiveAds]);

	useEffect(() => {
		const appendClickThroughTrackingTag = (uri) => {
			const param = 'ctpv=xlrecirc';
			const updatedUri = uri.includes('?') ? `${uri}&${param}` : `${uri}?${param}`;

			return updatedUri;
		};

		const getData = async () => {
			try {
				const response = await fetchArticles(true);
				setData(response.map((article) => ({ ...article, uri: appendClickThroughTrackingTag(article.uri) })));
			} catch (e) {
				console.log('Error fetching data 2', e);
			}
		};

		if (isVetted) {
			setData(vettedRecircData);
		} else {
			getData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const handleResize = () => {
			setIsSmallDevice(window.innerWidth <= 768);
		};

		handleResize();

		window.addEventListener('resize', handleResize, { passive: true });

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		const mobileXArray = document.querySelectorAll(mobileAdsSelector);
		setMobileInlineAds(mobileXArray.length);
	}, [mobileAdsSelector]);

	useEffect(() => {
		const blockComponent = window.forbes['simple-site'].specialSlot || window.forbes['simple-site'].swimLane;
		setShouldBlockComponent(blockComponent);
	}, []);

	const shouldShowXLRecirc = (!shouldBlockComponent || shouldForceRender) && mobileInlineAds !== undefined;

	if (!shouldShowXLRecirc) return null;

	// 12 articles are required to render the XL recirc
	const MIN_ARTICLES_REQUIRED = 12;

	return data && !!data?.length && data.length > MIN_ARTICLES_REQUIRED && (
		<>
			{showAds && <InlineAdX AdContainerComponent={AdContainerComponent} isMobile={isSmallDevice} index={mobileInlineAds + 1} customTargeting="mobilex:stream1" addProgressiveAd={addProgressiveAd} />}
			<div className={cx('xl-recirc-container', className, { 'vetted-recirc-container': isVetted })}>
				<div className="xl-recirc">
					<h1 className="section-title">
						More From Forbes
					</h1>
					<FourCardArticle
						CardArticleComponent={CardArticleComponent}
						CardStreamComponent={CardStreamComponent}
						themeColor={themeColor}
						data={data.slice(0, 4)}
						isSmallDevice={isSmallDevice}
					/>
					{showAds && isSmallDevice && <InlineAdX AdContainerComponent={AdContainerComponent} isMobile index={mobileInlineAds + 2} customTargeting="mobilex:stream2" addProgressiveAd={addProgressiveAd} />}
					<div className="two-sections">
						{data.slice(4, 6).map(({
							index, image, uri, alt, size, title, isPremium, authors, published_date: timestamp,
						}) => (
							<CardArticleComponent
								themeColor={themeColor}
								key={`${title}-${index}`}
								image={image}
								href={uri}
								alt={alt}
								size={size}
								title={title}
								isPremium={isPremium}
								className="two-card-article"
								timestamp={isSmallDevice ? new Date(timestamp).getTime() : null}
								classNameByLines="custom-stream"
								onClick={() => triggerGAEvent(`More From Forbes - XL Recirc - Story ${index + 1}`, index + 1)}
								isLazyLoading
								authors={[
									{
										link: authors[0]?.link,
										name: authors[0]?.name,
										type: authors[0]?.type,
									},
								]}
							/>
						))}
					</div>
					<FourCardArticle
						CardArticleComponent={CardArticleComponent}
						CardStreamComponent={CardStreamComponent}
						themeColor={themeColor}
						isReversed
						data={data.slice(6, 10)}
						hideImage
					/>
					{showAds && isSmallDevice && <InlineAdX AdContainerComponent={AdContainerComponent} isMobile index={mobileInlineAds + 3} customTargeting="mobilex:stream3" addProgressiveAd={addProgressiveAd} />}
					<div className="three-card-stream">
						{data.slice(10, 13).map(({
							index, image, uri, title, published_date: timestamp, authors,
						}) => (
							<CardStreamComponent
								className="card-stream-container"
								key={`${title}-${index}`}
								articleUrl={uri}
								title={title}
								timestamp={new Date(timestamp).getTime()}
								target="_self"
								description=""
								imageSrc={isSmallDevice ? null : image.replace('width=400', 'width=160')}
								onClick={() => triggerGAEvent(`More From Forbes - XL Recirc - Story ${index + 1}`, index + 1)}
								loading="lazy"
								byLineProps={{
									author: [
										{
											link: authors[0]?.link,
											name: authors[0]?.name,
											type: authors[0]?.type,
										},
									],
									displayBy: true,
									size: 'small',
									className: 'custom-stream',
								}}
							/>
						))}
					</div>
				</div>
				{showAds && !isSmallDevice
					&& (
						<div className="recirc-right-rail">
							<AdContainerComponent type="rightRail" showLabel>
								<fbs-ad position="stream" custom-targeting="recx:stream1,mffxlrecirc:true" ref={addProgressiveAd} progressive="true" />
							</AdContainerComponent>
						</div>
					)}
			</div>
		</>
	);
};

XLRecirc.propTypes = {
	showAds: PropTypes.bool,
	className: PropTypes.string,
	isVetted: PropTypes.bool,
	vettedRecircData: PropTypes.array,
	mobileAdsSelector: PropTypes.string,
	shouldForceRender: PropTypes.bool,
	themeColor: PropTypes.string,
	CardArticleComponent: PropTypes.node.isRequired,
	CardStreamComponent: PropTypes.node.isRequired,
	AdContainerComponent: PropTypes.node.isRequired,
};

export default XLRecirc;
